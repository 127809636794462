import {
  takeLatest,
  takeLeading,
  all,
  put,
  call,
  select,
} from "redux-saga/effects";

import type {
  LoadInviteResType,
  VendorReloginLinkResType,
} from "@ob/api/types";
import { fetchLoadInvite } from "@ob/api/vendor/invite";
import { fetchSendVendorReloginLink } from "@ob/api/vendor/login";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  BusinessInviteDbType,
  BusinessPersonDbType,
  isKeyBusinessStructureType,
  isKeyInDBAddressType,
  AddressDbType,
} from "@ob/redux/types/dbTypes";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import tracer from "@ob/tracing";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";

export default function* authSaga() {
  yield all([
    takeLatest(va.auth.getUserData, onGetUserData),
    takeLatest(va.auth.setExtraParams, onSetExtraParams),
    takeLeading(va.auth.sendReloginLink, onSendReloginLink),
  ]);
}

type HydrateKycStateArgType = {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
  address: AddressDbType;
  taxIdExists: boolean;
  needsPaymentDest: boolean;
  userType: string;
  business: BusinessInviteDbType | null;
};

type HydrateKybStateArgType = {
  businessName: string;
  doingBusinessAs: string;
  businessType: string;
  ein: string;
  address: AddressDbType;
  admin: BusinessPersonDbType;
  controller: BusinessPersonDbType;
  beneficialOwners: BusinessPersonDbType[];
};

function* onGetUserData(action: ReturnType<typeof va.auth.getUserData>) {
  try {
    const { jwt } = action.payload;
    yield all([
      put(va.auth.apiFetching(true)),
      put(
        va.auth.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: LoadInviteResType = yield call(fetchLoadInvite, jwt, otpVerify);
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.auth.apiSuccess(false)),
          put(va.auth.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to retrieve invite",
          tracer.ids.domain.SAGAS.auth,
        );
        yield put(va.routes.redirect("/phone"));
      } else {
        yield all([
          put(va.auth.setJWT(jwt)),
          put(va.auth.apiError(res.error)),
          put(va.auth.apiFetching(false)),
        ]);
      }
    } else {
      const kycStateArgs: HydrateKycStateArgType = {
        email: res.data.email,
        phone: res.data.phone,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        dob: res.data.dob,
        address: res.data.address,
        taxIdExists: res.data.taxIdExists,
        needsPaymentDest: res.data.needsPaymentDest,
        business: res.data.business,
        userType: res.data.userType,
      };
      yield call(hydrateKycState, kycStateArgs);
      if (res.data.business) {
        const kybStateArgs: HydrateKybStateArgType = {
          businessName: res.data.business.businessName,
          doingBusinessAs: res.data.business.doingBusinessAs,
          businessType: res.data.business.businessType,
          ein: res.data.business.ein,
          address: res.data.business.address,
          admin: res.data.business.admin,
          controller: res.data.business.controller,
          beneficialOwners: res.data.business.beneficialOwners,
        };
        yield call(hydrateKybState, kybStateArgs);
      }
      yield all([
        put(va.auth.apiSuccess(true)),
        put(va.auth.apiFetching(false)),
        put(va.auth.setJWT(jwt)),
        put(va.auth.setLoggedIn(true)),
        put(va.phone.setOtpValid(true)),
        put(va.auth.setUserData(res.data)),
      ]);
      tracer.setSessionAttrs({ userId: res.data.vendorId, isLoggedIn: true });
      if (res.data.status !== "unregistered") {
        yield put(va.submit.apiSuccess(true));
      }
      if (res.data.status === "approved") {
        if (action.payload.options.redirect) {
          yield put(va.routes.redirect(action.payload.options.redirect));
        }
      } else if (
        ["manual_review", "suspended", "rejected", "document"].includes(
          res.data.status,
        )
      ) {
        switch (res.data.status) {
          case "manual_review":
            tracer.critical(
              "Vendor requires manual review",
              tracer.ids.domain.SAGAS.auth,
              { status: res.data.status, jwt },
            );
            break;
          default:
            tracer.warn(
              "Vendor's status is not `approved`",
              tracer.ids.domain.SAGAS.auth,
              { status: res.data.status, jwt },
            );
        }
        yield put(va.routes.redirect("/manual_review"));
      } else if (res.data.status === "pending") {
        yield put(va.submit.setInviteStatus(res.data.status));
        yield put(va.routes.redirect(`/submit/${res.data.userType}`));
      }
    }
  } catch (error) {
    const errMsg =
      "An error occured while loading your invite. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.auth.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.auth.apiFetching(false)),
    ]);
  }
}

function* onSetExtraParams(action: ReturnType<typeof va.auth.setExtraParams>) {
  try {
    const params = new URLSearchParams(action.payload.extraParams);
    const _phone = params.get("phone");
    const phone = _phone?.includes(" 1") ? _phone.slice(2) : _phone;
    const data: HydrateKycStateArgType = {
      email: params.get("email") || "",
      phone: phone || "",
      firstName: params.get("first_name") || "",
      lastName: params.get("last_name") || "",
      dob: params.get("dob") || "",
      address: {
        street1: params.get("address_line1") || "",
        street2: params.get("address_line2") || "",
        city: params.get("address_city") || "",
        state: params.get("address_state") || "",
        zip: params.get("address_postal_code") || "",
      },
      taxIdExists: false,
      needsPaymentDest: true,
      userType: "",
      business: null, // TODO: Needed?
    };
    yield call(hydrateKycState, data);
    yield put(va.submit.setManualUpdateInvite(true));
  } catch (error) {
    const errMsg =
      "An error occured while setting extra params. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.auth.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.auth.apiFetching(false)),
    ]);
  }
}

function* onSendReloginLink() {
  try {
    yield all([
      put(va.auth.apiFetching(true)),
      put(va.auth.apiSuccess(false)),
      put(va.auth.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const res: VendorReloginLinkResType = yield call(
      fetchSendVendorReloginLink,
      jwt,
    );
    if ("error" in res) {
      tracer.warn(
        "Vendor relogin link request failed",
        tracer.ids.domain.SAGAS.auth,
      );
      if (res.error.status === 400) {
        yield all([
          put(
            va.auth.apiError({
              message: res.error.message,
              status: MANUAL_ERROR_CODE,
            }),
          ),
          put(va.auth.apiSuccess(false)),
          put(va.auth.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.auth.apiError(res.error)),
          put(va.auth.apiSuccess(false)),
          put(va.auth.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(va.auth.apiSuccess(true)),
        put(va.auth.apiFetching(false)),
      ]);
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(va.auth.apiFetching(false)),
      put(
        va.auth.apiError({
          message:
            "Oops. We were unable to send a login link. Please try again.",
          status: MANUAL_ERROR_CODE,
        }),
      ),
    ]);
  }
}
function* hydrateKycState(data: HydrateKycStateArgType) {
  try {
    if (data.phone) {
      yield put(va.phone.setPhoneValue("phoneNumber", data.phone));
    }
    if (data.firstName) {
      yield put(va.kyc.fullName.setNameValue("firstName", data.firstName));
    }
    if (data.lastName) {
      yield put(va.kyc.fullName.setNameValue("lastName", data.lastName));
    }
    if (data.dob) {
      yield put(va.kyc.dob.setDOB(data.dob));
    }
    if (data.taxIdExists) {
      yield put(va.kyc.ssn.apiSuccess(true));
    }
    if (data.address) {
      for (const key in data.address) {
        if (isKeyInDBAddressType(key)) {
          yield put(va.kyc.address.setAddressValue(key, data.address[key]));
        }
      }
    }
    if (data.email) {
      yield put(va.kyc.email.setEmail(data.email));
    }
    if (!data.needsPaymentDest) {
      yield put(va.payDest.apiSuccess(true));
    }
  } catch (error) {
    const errMsg = "An error occured while hydrating your invite.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.auth.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.auth.apiFetching(false)),
    ]);
  }
}

function* hydrateKybState(data: HydrateKybStateArgType) {
  try {
    if (data.businessName) {
      yield put(va.kyb.business.info.setBusinessName(data.businessName));
    }
    if (data.ein) {
      yield put(va.kyb.business.info.setEin(data.ein));
    }
    if (data.businessType) {
      if (isKeyBusinessStructureType(data.businessType)) {
        yield put(va.kyb.business.info.setBusinessStructure(data.businessType));
      } else {
        yield put(
          va.kyb.business.info.setBusinessStructure(BusinessStructureType.NONE),
        );
      }
    }
    if (data.address) {
      for (const key in data.address) {
        if (isKeyInDBAddressType(key)) {
          yield put(
            va.kyb.business.address.setAddressValue(key, data.address[key]),
          );
        }
      }
    }
    if (data.admin) {
      if (data.admin.firstName) {
        yield put(va.kyb.admin.info.setFirstName(data.admin.firstName));
      }
      if (data.admin.lastName) {
        yield put(va.kyb.admin.info.setLastName(data.admin.lastName));
      }
      if (data.admin.email) {
        yield put(va.kyb.admin.info.setEmail(data.admin.email));
      }
      if (data.admin.dob) {
        yield put(va.kyb.admin.info.setDOB(data.admin.dob));
      }
      if (data.admin.ssnExists) {
        yield put(va.kyb.admin.info.ssnApiSuccess(true));
      }
      if (data.admin.address) {
        for (const key in data.admin.address) {
          if (isKeyInDBAddressType(key)) {
            yield put(
              va.kyb.admin.address.setAddressValue(
                key,
                data.admin.address[key],
              ),
            );
          }
        }
      }
    }
    if (data.controller) {
      if (data.controller.firstName) {
        yield put(
          va.kyb.controller.info.setFirstName(data.controller.firstName),
        );
      }
      if (data.controller.lastName) {
        yield put(va.kyb.controller.info.setLastName(data.controller.lastName));
      }
      if (data.controller.email) {
        yield put(va.kyb.controller.info.setEmail(data.controller.email));
      }
      if (data.controller.dob) {
        yield put(va.kyb.controller.info.setDOB(data.controller.dob));
      }
      if (data.controller.ssnExists) {
        yield put(va.kyb.controller.info.ssnApiSuccess(true));
      }
      if (data.controller.title) {
        yield put(va.kyb.controller.info.setTitle(data.controller.title));
      }
      if (data.controller.address) {
        for (const key in data.controller.address) {
          if (isKeyInDBAddressType(key)) {
            yield put(
              va.kyb.controller.address.setAddressValue(
                key,
                data.controller.address[key],
              ),
            );
          }
        }
      }
    }
    if (
      Array.isArray(data.beneficialOwners) &&
      data.beneficialOwners.length > 0
    ) {
      yield put(va.kyb.owners.hydrateOwners(data.beneficialOwners));
    }
  } catch (error) {
    const errMsg = "An error occured while hydrating your invite.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.auth.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.auth.apiFetching(false)),
    ]);
  }
}
