import AuthGuard from "@ob/components/AuthGuard";
import DeleteSuccess from "@ob/layouts/VendorOnboarding/components/DeleteSuccessPage";
import ManualReview from "@ob/components/ManualReview";
import SessionExpired from "@ob/components/SessionExpired";
import RedirectLanding from "@ob/components/RedirectLanding";
import ErrorBoundary from "@ob/components/ErrorBoundary";
import KybConfigGuard from "@ob/mantis/utils/route-guard/KybConfigGuard";
import SubmitInvite from "./components/SubmitInvite";
import Authorize from "./components/Authorize";
import PhoneInput from "./components/PhoneNumber";
import PhoneCode from "./components/PhoneCode";
import FullName from "./components/KYC/FullName";
import DOB from "./components/KYC/Birthday";
import Address from "./components/KYC/Address";
import SSN from "./components/KYC/SSN";
import Email from "./components/KYC/Email";
import CreditCard from "./components/CreditCard";
import VendorOnboarding from "./index";
import LinkedAccounts from "./components/LinkedAccounts";
import AddAcctSuccess from "./components/AddAcctSuccessPage";
import NewBankAccount from "./components/NewBankAccount";
import RelinkAccount from "./components/RelinkAccount";
import GetStarted from "./components/GetStarted";
import BusinessInfo from "./components/KYB/BusinessInfo";
import BusinessAddress from "./components/KYB/BusinessAddress";
import AdminInfo from "./components/KYB/AdminInfo";
import AdminAddress from "./components/KYB/AdminAddress";
import AdminBeneficialOwnerCheck from "./components/KYB/AdminBeneficialOwnerCheck";
import BusinessControllerCheck from "./components/KYB/BusinessControllerCheck";
import BusinessControllerTitle from "./components/KYB/BusinessControllerTitle";
import BusinessControllerRegister from "./components/KYB/BusinessControllerRegister";
import BusinessControllerInfo from "./components/KYB/BusinessControllerInfo";
import BusinessControllerAddress from "./components/KYB/BusinessControllerAddress";
import BusinessControllerBeneficialOwnerCheck from "./components/KYB/BusinessControllerBeneficialOwnerCheck";
import BeneficialOwnerInfo from "./components/KYB/BeneficialOwnerInfo";
import BeneficialOwnerAddress from "./components/KYB/BeneficialOwnerAddress";
import Review from "./components/KYB/Review";

export default {
  path: "/",
  element: <VendorOnboarding />,
  errorElement: <ErrorBoundary fallback />,
  children: [
    {
      path: "authorize",
      element: <Authorize />,
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "credit_card",
      element: (
        <AuthGuard>
          <CreditCard />
        </AuthGuard>
      ),
    },
    {
      path: "delete_success",
      element: (
        <AuthGuard>
          <DeleteSuccess />
        </AuthGuard>
      ),
    },
    {
      path: "success",
      element: <AddAcctSuccess />,
    },
    {
      path: "manual_review",
      element: (
        <AuthGuard>
          <ManualReview />
        </AuthGuard>
      ),
    },
    {
      path: "session_expired",
      element: (
        <AuthGuard>
          <SessionExpired />
        </AuthGuard>
      ),
    },
    {
      path: "verify/:otpVerify/astra_redirect",
      element: <RedirectLanding />,
    },
    {
      path: "linked_accounts",
      element: (
        <AuthGuard>
          <LinkedAccounts />
        </AuthGuard>
      ),
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "bank_account",
      element: (
        <AuthGuard>
          <NewBankAccount />
        </AuthGuard>
      ),
    },
    {
      path: "phone",
      element: <PhoneInput />,
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "phone_verification",
      element: <PhoneCode />,
      errorElement: <ErrorBoundary fallback />,
    },
    {
      path: "kyc/name",
      element: (
        <AuthGuard>
          <FullName />
        </AuthGuard>
      ),
    },
    {
      path: "kyc/birthday",
      element: (
        <AuthGuard>
          <DOB />
        </AuthGuard>
      ),
    },
    {
      path: "kyc/address",
      element: (
        <AuthGuard>
          <Address />
        </AuthGuard>
      ),
    },
    {
      path: "kyc/ssn",
      element: (
        <AuthGuard>
          <SSN />
        </AuthGuard>
      ),
    },
    {
      path: "kyc/email",
      element: (
        <AuthGuard>
          <Email />
        </AuthGuard>
      ),
    },
    {
      path: "submit/:userType",
      element: (
        <AuthGuard>
          <SubmitInvite />
        </AuthGuard>
      ),
    },
    {
      path: "relink_account",
      element: (
        <AuthGuard>
          <RelinkAccount />
        </AuthGuard>
      ),
    },
    {
      path: "get_started",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <GetStarted />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_info",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessInfo />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_address",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessAddress />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/admin_info",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <AdminInfo />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/admin_address",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <AdminAddress />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/admin_beneficial_owner_check",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <AdminBeneficialOwnerCheck />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_check",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerCheck />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_register",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerRegister />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_title",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerTitle />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_info",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerInfo />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_address",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerAddress />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/business_controller_beneficial_owner_check",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BusinessControllerBeneficialOwnerCheck />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/review",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <Review />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/owners_info",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BeneficialOwnerInfo />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/owners_info/:index",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BeneficialOwnerInfo />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/owners_address",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BeneficialOwnerAddress />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
    {
      path: "kyb/owners_address/:index",
      element: (
        <AuthGuard>
          <KybConfigGuard>
            <BeneficialOwnerAddress />
          </KybConfigGuard>
        </AuthGuard>
      ),
    },
  ],
};
