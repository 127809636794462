import { all, put, call, select, delay, takeLeading } from "redux-saga/effects";

import {
  type GetInviteStatusResType,
  type SubmitInviteReqType,
  type SubmitInviteResType,
  type AddressReqType,
} from "@ob/api/types";
import { fetchSubmitInvite, fetchGetInviteStatus } from "@ob/api/vendor/invite";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectCity,
  selectState,
  selectStreet1,
  selectStreet2,
  selectZip,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/address";
import { selectPhoneNumber } from "@ob/layouts/VendorOnboarding/redux/selectors/phone";
import { selectEmail } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/email";
import { selectSSN } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/ssn";
import { selectDob } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/dob";
import {
  selectFirstName,
  selectLastName,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/fullName";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { formatApiBirthday } from "@ob/utils/formatBirthday";
import tracer from "@ob/tracing";
import { postMsgUserStatusManualReview } from "../utils/postMesage";

export default function* submitInviteSaga() {
  yield all([takeLeading(va.submit.submitInvite, onSubmit)]);
}

export function* onSubmit(action: ReturnType<typeof va.submit.submitInvite>) {
  try {
    yield all([
      put(va.submit.apiSuccess(false)),
      put(va.submit.apiFetching(true)),
      put(
        va.submit.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);

    const street1: string = yield select(selectStreet1);
    const street2: string = yield select(selectStreet2);
    const city: string = yield select(selectCity);
    const state: string = yield select(selectState);
    const zip: string = yield select(selectZip);
    const address: AddressReqType = {
      line1: street1,
      line2: street2,
      city,
      state,
      postal_code: zip,
    };
    const phone: string = yield select(selectPhoneNumber);
    const email: string = yield select(selectEmail);
    const ssn: string = yield select(selectSSN);
    const dob: string = yield select(selectDob);
    const dobFormatted = formatApiBirthday(dob);
    const firstName: string = yield select(selectFirstName);
    const lastName: string = yield select(selectLastName);
    const reqData: SubmitInviteReqType = {
      address,
      phone,
      email,
      dob: dobFormatted,
      tax_id: ssn,
      first_name: firstName,
      last_name: lastName,
    };
    if (!reqData) {
      console.error("reqData is undefined");
      return;
    }
    const res: SubmitInviteResType = yield call(
      fetchSubmitInvite,
      jwt,
      otpVerify,
      reqData,
      action.payload.userType,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.submit.apiSuccess(false)),
          put(va.submit.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to retrieve invite",
          tracer.ids.domain.SAGAS.submit,
          { jwt },
        );
        yield all([
          put(va.routes.redirect("/phone")),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to submit onboarding invite!",
          tracer.ids.domain.SAGAS.submit,
        );
        yield all([
          put(va.submit.apiError(res.error)),
          put(va.submit.apiFetching(false)),
          put(va.submit.apiSuccess(false)),
        ]);
      }
    } else {
      tracer.info(
        "User has successfully submitted onboarding invite",
        tracer.ids.domain.SAGAS.submit,
      );
      yield all([
        put(va.submit.setInviteData(res.data)),
        call(getStatusUpdate),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while submitting your information. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.submit.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.submit.apiFetching(false)),
    ]);
  }
}

export function* getStatusUpdate() {
  const startTime = Date.now();
  let statusReceived = false;
  let jwt: string = "";

  while (!statusReceived) {
    try {
      jwt = yield select(selectJWT);
      const otpVerify: string = yield select(selectOTPVerify);
      const res: GetInviteStatusResType = yield call(
        fetchGetInviteStatus,
        jwt,
        otpVerify,
      );
      if ("error" in res) {
        if (res.error.status === 401) {
          statusReceived = true;
          yield all([put(va.routes.redirect("/session_expired"))]);
        } else {
          tracer.warn(
            "Unable to get invite status",
            tracer.ids.domain.SAGAS.kyc,
            {
              jwt,
            },
          );
          statusReceived = true;
          yield all([
            put(va.submit.apiError(res.error)),
            put(va.submit.apiFetching(false)),
            put(va.routes.redirect("/manual_review")),
          ]);
          yield call(postMsgUserStatusManualReview);
        }
      } else if ((res.data.status as string) !== "pending") {
        statusReceived = true;
        yield all([
          put(va.submit.apiFetching(false)),
          put(va.submit.apiSuccess(true)),
          put(va.auth.setUserStatus(res.data.status)),
        ]);

        if (res.data.status === "approved") {
          tracer.info(
            "User's vendor profile has been approved",
            tracer.ids.domain.SAGAS.kyc,
          );
          yield put(va.routes.redirect("/linked_accounts"));
        } else if (
          ["manual_review", "suspended", "rejected", "document", ""].includes(
            res.data.status,
          )
        ) {
          tracer.critical(
            "User has been sent to manual review",
            tracer.ids.domain.SAGAS.kyc,
          );
          yield put(va.routes.redirect("/manual_review"));
        }
      } else {
        yield delay(1000);
      }
    } catch (error) {
      const errMsg =
        "An error occured while submitting your information. Please try again.";
      if (error instanceof Error) {
        console.error(error.message);
      }
      yield all([
        put(va.submit.apiFetching(false)),
        put(
          va.submit.apiError({
            message: errMsg,
            status: MANUAL_ERROR_CODE,
          }),
        ),
      ]);
      statusReceived = true;
    }
    const POLL_TIME_OUT_IN_MILLISECONDS = 60000;
    const currentRunLen = Date.now() - startTime;
    if (currentRunLen > POLL_TIME_OUT_IN_MILLISECONDS) {
      statusReceived = true;
      tracer.critical(
        "User's KYC submit status timed-out. They've been sent to manual review",
        tracer.ids.domain.SAGAS.kyc,
      );
      yield put(va.routes.redirect("/manual_review"));
    }
  }
}
