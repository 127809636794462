import { all, takeLeading, put, select, call, delay } from "redux-saga/effects";
import { BankAcctDbType, CreditCardDbType } from "@ob/redux/types/dbTypes";
import type {
  AstraParamsResType,
  GetCardsResType,
  GetAcctsResType,
  CreateAcctResType,
} from "@ob/api/types";
import { fetchGetAstraParams } from "@ob/api/vendor/astra";
import {
  fetchGetCards,
  fetchDeleteCard,
  fetchGetAccts,
  fetchCreateAcct,
  fetchDeleteAcct,
} from "@ob/api/vendor/payDest";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectAllBanks,
  selectAllCards,
} from "@ob/layouts/VendorOnboarding/redux/selectors/payDest";
import tracer from "@ob/tracing";
import { postMsgAcctAdded } from "../utils/postMesage";

export default function* payDestSaga() {
  yield all([
    takeLeading(va.payDest.getCardReqParams, onGetCardReqParams),
    takeLeading(va.payDest.getPaymentDests, onGetPaymentDests),
    takeLeading(va.payDest.deleteCard, onDeleteCard),
    takeLeading(va.payDest.deleteBankAcct, onDeleteBankAcct),
    takeLeading(va.payDest.submitBankAcct, onSubmitBankAcct),
    takeLeading(
      va.payDest.getUserDataThenCheckForNewPayDests,
      onGetUserDataThenCheckForNewPayDests,
    ),
    takeLeading(va.payDest.notifyAcctStatusChange, onNotifyAcctStatusChange),
    takeLeading(va.payDest.deleteDisabledCard, onDeleteDisabledCard),
    takeLeading(
      va.payDest.deleteDisabledBankAccount,
      onDeleteDisabledBankAccount,
    ),
  ]);
}

function* onGetCardReqParams() {
  try {
    yield all([
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: AstraParamsResType = yield call(
      fetchGetAstraParams,
      jwt,
      otpVerify,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to retrieve invite",
          tracer.ids.domain.SAGAS.payDest,
          { jwt },
        );
        yield all([
          put(va.routes.redirect("/phone")),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to get Card-Link credentials.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
          put(va.payDest.apiSuccess(false)),
        ]);
      }
    } else {
      yield all([
        put(va.payDest.apiSuccess(true)),
        put(va.payDest.apiFetching(false)),
        put(va.payDest.setCardReqParams(res.data)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Name. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

export function* onGetPaymentDests() {
  try {
    yield all([
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const resCards: GetCardsResType = yield call(fetchGetCards, jwt, otpVerify);
    const resBanks: GetAcctsResType = yield call(fetchGetAccts, jwt, otpVerify);
    const errorRes = [resCards, resBanks].find((res) => "error" in res);
    if (errorRes && "error" in errorRes) {
      if (errorRes.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(errorRes.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to retrieve their payment methods.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(errorRes.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      if ("data" in resCards && resCards.data.cards.length > 0) {
        yield put(va.payDest.setCards(resCards.data.cards));
      }
      if ("data" in resBanks && resBanks.data.accounts.length > 0) {
        yield put(va.payDest.setBankAccts(resBanks.data.accounts));
      }
      const cards: (CreditCardDbType | BankAcctDbType)[] =
        "data" in resCards ? resCards.data.cards : [];
      const bankAccounts: (CreditCardDbType | BankAcctDbType)[] =
        "data" in resBanks ? resBanks.data.accounts : [];
      const payDests = cards.concat(bankAccounts);
      const hasAnEnabledPayDest = payDests.some((payDest) => !payDest.disabled);
      if (payDests.length > 0 && !hasAnEnabledPayDest) {
        tracer.warn(
          "User has no enabled accounts: redirecting to `relink_account`",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield put(va.routes.redirect("/relink_account"));
      }
      yield put(va.payDest.apiFetching(false));
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Name. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

export function* onDeleteCard(
  action: ReturnType<typeof va.payDest.deleteCard>,
) {
  try {
    yield all([
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: GetCardsResType = yield call(
      fetchDeleteCard,
      jwt,
      otpVerify,
      action.payload.id,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to delete their card",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      const oldCards: CreditCardDbType[] = yield select(selectAllCards);
      const newCards = oldCards.filter((card) => card.id !== action.payload.id);
      yield all([
        put(va.payDest.apiSuccess(true)),
        put(va.payDest.apiFetching(false)),
        put(va.payDest.setCards(newCards)),
      ]);
      if (action.payload.redirect) {
        tracer.info(
          "User successfully deleted card; redirecting user.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield put(va.payDest.apiSuccess(false));
        yield put(va.routes.redirect(action.payload.redirect));
      }
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Name. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

function* onDeleteBankAcct(
  action: ReturnType<typeof va.payDest.deleteBankAcct>,
) {
  try {
    yield all([
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: GetCardsResType = yield call(
      fetchDeleteAcct,
      jwt,
      otpVerify,
      action.payload.id,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to delete their bank account",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      const oldBanks: BankAcctDbType[] = yield select(selectAllBanks);
      const newBanks = oldBanks.filter((bank) => bank.id !== action.payload.id);
      yield all([
        put(va.payDest.apiSuccess(true)),
        put(va.payDest.apiFetching(false)),
        put(va.payDest.setBankAccts(newBanks)),
      ]);
      if (action.payload.redirect) {
        tracer.info(
          "User successfully deleted Bank Account; redirecting.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield put(va.payDest.apiSuccess(false));
        yield put(va.routes.redirect(action.payload.redirect));
      }
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Name. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

function* onSubmitBankAcct(
  action: ReturnType<typeof va.payDest.submitBankAcct>,
) {
  try {
    yield all([
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: CreateAcctResType = yield call(
      fetchCreateAcct,
      jwt,
      otpVerify,
      action.payload.info,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to submit their Banking Info.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(res.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      tracer.info(
        "User successfully created a Bank Acct.",
        tracer.ids.domain.SAGAS.payDest,
      );
      yield all([
        put(va.payDest.getPaymentDests()),
        call(onNotifyAcctStatusChange),
      ]);
      yield all([
        put(va.auth.apiSuccess(true)),
        put(va.payDest.apiSuccess(true)),
        put(va.payDest.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while submitting your Banking Info. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

export function* onGetUserDataThenCheckForNewPayDests() {
  const jwt: string = yield select(selectJWT);
  yield put(va.auth.getUserData(jwt, { redirect: "" }));
  yield put(va.payDest.notifyAcctStatusChange());
}

export function* onNotifyAcctStatusChange() {
  const startTime = Date.now();
  const TEN_SECONDS = 10000;
  let shouldPoll = true;
  let jwt: string = "";
  while (Date.now() - startTime < TEN_SECONDS && shouldPoll) {
    try {
      jwt = yield select(selectJWT);
      const otpVerify: string = yield select(selectOTPVerify);
      const resCards: GetCardsResType = yield call(
        fetchGetCards,
        jwt,
        otpVerify,
      );
      const resBanks: GetAcctsResType = yield call(
        fetchGetAccts,
        jwt,
        otpVerify,
      );
      const errorRes = [resCards, resBanks].find((res) => "error" in res);
      if (errorRes && "error" in errorRes) {
        if (errorRes.error.status === 401) {
          shouldPoll = false;
          yield all([
            put(va.routes.redirect("/session_expired")),
            put(va.payDest.apiError(errorRes.error)),
          ]);
        } else {
          tracer.critical(
            "Unable to notify merchant that user added payment method.",
            tracer.ids.domain.SAGAS.payDest,
          );
          shouldPoll = false;
          yield put(va.payDest.apiError(errorRes.error));
        }
      } else {
        let redirect = "";
        let msgSent = false;
        if ("data" in resCards && resCards.data.cards.length > 0) {
          const hasDisabledCard = resCards.data.cards.some(
            (card) => card.disabled,
          );
          if (hasDisabledCard) {
            shouldPoll = false;
            redirect = "relink_account";
          } else {
            const activeCard = resCards.data.cards.find(
              (card) => card.active && card.isPushEnabled,
            );
            if (activeCard && !msgSent) {
              tracer.info(
                "User successfully added a card; notified merchant.",
                tracer.ids.domain.SAGAS.payDest,
              );
              postMsgAcctAdded();
              msgSent = true;
              redirect = `success?auth=${jwt}`;
            }
          }
        }
        if ("data" in resBanks && resBanks.data?.accounts?.length > 0) {
          const activeBank = resBanks.data.accounts.find((bank) => bank.active);
          const hasDisabledBankAccount = resBanks.data.accounts.some(
            (bank) => bank.disabled,
          );
          if (activeBank && !msgSent) {
            tracer.info(
              "User successfully added a bank account; notified merchant.",
              tracer.ids.domain.SAGAS.payDest,
            );
            postMsgAcctAdded();
            msgSent = true;
          }
          if (hasDisabledBankAccount) {
            shouldPoll = false;
            redirect = "relink_account";
          }
        }
        if (msgSent) {
          shouldPoll = false;
        }
        if (redirect) {
          yield put(va.routes.redirect(redirect));
          shouldPoll = false;
        }
      }
      yield delay(500);
    } catch (error) {
      shouldPoll = false;
      const errMsg =
        "An error occured while submitting your information. Please try again.";
      if (error instanceof Error) {
        console.error(errMsg, "\n", error.message);
      }
      tracer.captureException(
        new Error("User unable to retrieve their payment methods."),
        tracer.ids.critical.LOCAL_API_ERROR,
      );
    }
  }
}

export function* onDeleteDisabledCard(
  action: ReturnType<typeof va.payDest.deleteDisabledCard>,
) {
  try {
    yield all([
      put(va.payDest.apiSuccess(false)),
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const resCards: GetCardsResType = yield call(fetchGetCards, jwt, otpVerify);
    if ("error" in resCards) {
      if (resCards.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(resCards.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to relink their unsupported card during delete step.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(resCards.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      const cardId = resCards.data.cards.find((card) => card.disabled)?.id;
      if (cardId) {
        yield all([
          put(va.payDest.apiFetching(false)),
          put(
            va.payDest.deleteCard(cardId as string, action.payload.nextScreen),
          ),
        ]);
      } else {
        console.error("No disabled card found.");
      }
    }
  } catch (error) {
    const errMsg = "An error occured. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}

export function* onDeleteDisabledBankAccount(
  action: ReturnType<typeof va.payDest.deleteDisabledBankAccount>,
) {
  try {
    yield all([
      put(va.payDest.apiSuccess(false)),
      put(va.payDest.apiFetching(true)),
      put(va.payDest.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const resAccts: GetAcctsResType = yield call(fetchGetAccts, jwt, otpVerify);
    if ("error" in resAccts) {
      if (resAccts.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.payDest.apiError(resAccts.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      } else {
        tracer.warn(
          "User unable to relink their unsupported bank account during delete step.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiError(resAccts.error)),
          put(va.payDest.apiFetching(false)),
        ]);
      }
    } else {
      const acctId = resAccts.data.accounts.find((acct) => acct.disabled)?.id;
      if (acctId) {
        tracer.info(
          "User successfully deleted disabled bank account.",
          tracer.ids.domain.SAGAS.payDest,
        );
        yield all([
          put(va.payDest.apiFetching(false)),
          put(
            va.payDest.deleteBankAcct(
              acctId as string,
              action.payload.nextScreen,
            ),
          ),
        ]);
      } else {
        tracer.critical(
          "User unable to delete disabled bank account: id not found.",
          tracer.ids.domain.SAGAS.payDest,
          { jwt, accountId: acctId || "<not-found>" },
        );
        console.error("No disabled bank account found.");
      }
    }
  } catch (error) {
    const errMsg = "An error occured. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.payDest.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.payDest.apiFetching(false)),
    ]);
  }
}
