import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import type { AstraParamsResType } from "@ob/api/types";

export const fetchGetAstraParams = async (
  jwt: string,
  otpVerify: string,
): Promise<AstraParamsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/astra?auth=${jwt}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`otp_verify:${otpVerify}`)}`,
      },
    },
  );
  return response;
};

export default fetchGetAstraParams;
