import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { KyvUserTypes, type UpdateInviteResType } from "@ob/api/types";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { selectDob } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/dob";
import { formatApiBirthday } from "@ob/utils/formatBirthday";
import tracer from "@ob/tracing";

export default function* dobSaga() {
  yield takeLatest(va.kyc.dob.submitDOB, onSubmitDOB);
}

export function* onSubmitDOB() {
  try {
    const dob: string = yield select(selectDob);
    if (!dob) return;
    yield all([
      put(va.kyc.dob.apiSuccess(false)),
      put(va.kyc.dob.apiFetching(true)),
      put(
        va.kyc.dob.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      otpVerify,
      { dob: formatApiBirthday(dob) },
      KyvUserTypes.INDIVIDUAL,
    );
    if ("error" in res) {
      tracer.warn("User failed to update DOB", tracer.ids.domain.SAGAS.kyc, {
        jwt,
      });
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyc.dob.apiError(res.error)),
          put(va.kyc.dob.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to set dob",
          tracer.ids.domain.SAGAS.kyc,
          {
            jwt,
          },
        );
        yield all([
          put(va.routes.redirect("/phone")),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.kyc.dob.apiError(res.error)),
          put(va.kyc.dob.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(va.kyc.dob.apiSuccess(true)),
        put(va.kyc.dob.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Date of Birth. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyc.dob.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyc.dob.apiFetching(false)),
    ]);
  }
}
