import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { KyvUserTypes, UpdateInviteResType } from "@ob/api/types";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectFirstName,
  selectLastName,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/fullName";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import tracer from "@ob/tracing";

export default function* fullNameSaga() {
  yield takeLatest(va.kyc.fullName.submitName, onSubmitFullName);
}

export function* onSubmitFullName() {
  try {
    const firstName: string = yield select(selectFirstName);
    const lastName: string = yield select(selectLastName);
    if (!firstName || !lastName) return;
    yield all([
      put(va.kyc.fullName.apiFetching(true)),
      put(va.kyc.fullName.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      otpVerify,
      {
        first_name: firstName,
        last_name: lastName,
      },
      KyvUserTypes.INDIVIDUAL,
    );
    if ("error" in res) {
      tracer.warn("User failed to update Name", tracer.ids.domain.SAGAS.kyc, {
        jwt,
      });
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyc.fullName.apiError(res.error)),
          put(va.kyc.fullName.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to set email",
          tracer.ids.domain.SAGAS.kyc,
          {
            jwt,
          },
        );
        yield all([
          put(va.routes.redirect("/phone")),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.kyc.fullName.apiError(res.error)),
          put(va.kyc.fullName.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(va.kyc.fullName.apiSuccess(true)),
        put(va.kyc.fullName.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Name. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyc.fullName.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyc.fullName.apiFetching(false)),
    ]);
  }
}
