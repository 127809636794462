import isDevEnv from "@ob/utils/envCheck";

// eslint-disable-next-line no-shadow
export enum PostMessage {
  AccountAdded = "account_added",
  UserStatusManualReview = "manual_review",
  NavigateHome = "navigate_home",
}

const doPostMessage = (msg: PostMessage) => {
  if (isDevEnv()) {
    console.log(`MOCK POST MSG: ${msg}`); // eslint-disable-line
  }
  if (window?.postMessage) {
    window.postMessage(msg, "*");
  }
  if (window?.parent?.postMessage) {
    window.parent.postMessage(msg, "*");
  }
  if (window?.top?.parent?.postMessage) {
    window.top.parent.postMessage(msg, "*");
  }
  // @ts-expect-error - ReactNativeWebView is not defined
  if (window?.ReactNativeWebView) {
    // @ts-expect-error - ReactNativeWebView is not defined
    window.ReactNativeWebView.postMessage(msg, "*");
  }
};

export function postMsgAcctAdded() {
  doPostMessage(PostMessage.AccountAdded);
}

export function postMsgUserStatusManualReview() {
  doPostMessage(PostMessage.UserStatusManualReview);
}

export function postMsgNavigateHome() {
  doPostMessage(PostMessage.NavigateHome);
}
