import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { persistApi, useAppDispatch, useAppSelector } from "@ob/redux/store";
import {
  Box,
  Stack,
  Button,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Divider,
} from "@mui/material";
import { FormControl } from "@mui/base";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectClientId,
  selectSessionToken,
  selectUserIntentId,
} from "@ob/layouts/VendorOnboarding/redux/selectors/payDest";

export default function DevDashboard() {
  const dispatch = useAppDispatch();
  const sessionToken = useAppSelector(selectSessionToken);
  const userIntentId = useAppSelector(selectUserIntentId);
  const clientId = useAppSelector(selectClientId);

  const [openPurgeSuccess, setOpenPurgeSuccess] = useState(false);
  const [uiSessionToken, setSessionToken] = useState(sessionToken || "");
  const [uiUserIntentId, setUserIntentId] = useState(userIntentId || "");
  const [uiClientId, setClientId] = useState(clientId || "");

  const navigate = useNavigate();

  const handlePurge = () => {
    persistApi.purge().then(() => {
      setOpenPurgeSuccess(true);
      setTimeout(() => {
        setOpenPurgeSuccess(false);
      }, 3000);
    });
  };

  const handleSaveIFrameParams = () => {
    dispatch(
      va.payDest.setCardReqParams({
        sessionToken: uiSessionToken,
        userIntentId: uiUserIntentId,
        clientId: uiClientId,
      }),
    );
    setSessionToken("");
    setUserIntentId("");
    setClientId("");
  };

  return (
    <>
      <Box
        gap={2}
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          minHeight: "100vh",
          backgroundColor: "#FFF",
        }}
      >
        <Typography variant="h1">Debugger</Typography>
        <Divider />
        <Stack justifyContent="start" gap={10}>
          <Stack gap={3}>
            <FormControl>
              <Typography variant="h3">Redux State</Typography>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={handlePurge}
              >
                Reset State
              </Button>
            </FormControl>
            <Divider />
            <FormControl>
              <Stack gap={1}>
                <Typography variant="h3">iFrame Params</Typography>
                <Typography variant="subtitle2">
                  <code>state.kyc.sessionToken</code>:{" "}
                  {sessionToken || "undefined"}
                </Typography>
                <TextField
                  onChange={(e) => setSessionToken(e.target.value)}
                  value={uiSessionToken}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="subtitle2">
                  <code>state.kyc.userIntentId</code>:{" "}
                  {userIntentId || "undefined"}
                </Typography>
                <TextField
                  onChange={(e) => setUserIntentId(e.target.value)}
                  value={uiUserIntentId}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="subtitle2">
                  <code>state.kyc.clientId</code>: {clientId || "undefined"}
                </Typography>
                <TextField
                  onChange={(e) => setClientId(e.target.value)}
                  value={uiClientId}
                />
                <Button
                  variant="contained"
                  color="info"
                  onClick={handleSaveIFrameParams}
                >
                  Save IFrame Params
                </Button>
              </Stack>
            </FormControl>
            <Stack gap={2}>
              <Typography variant="h3">Routes</Typography>
              <Button
                color="info"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/linked_accounts")}
              >
                Cards
              </Button>
              <Button variant="outlined" onClick={() => navigate("/phone")}>
                Phone
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/phone_verification")}
              >
                OTP Code
              </Button>
              <Button variant="outlined" onClick={() => navigate("/kyc/name")}>
                Name
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/kyc/birthday")}
              >
                D.O.B.
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/kyc/address")}
              >
                Address
              </Button>
              <Button variant="outlined" onClick={() => navigate("/kyc/ssn")}>
                SSN
              </Button>
              <Button variant="outlined" onClick={() => navigate("/kyc/email")}>
                Email
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/submit/individual")}
              >
                Create User
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/credit_card")}
              >
                Credit Card
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/delete_success")}
              >
                Delete Success
              </Button>
              <Button variant="outlined" onClick={() => navigate("/success")}>
                Add Card Success
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/manual_review")}
              >
                Manual Review
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/session_expired")}
              >
                Session Expired
              </Button>
              {/* <Button
                variant="outlined"
                onClick={() => navigate("/astra_redirect")}
              >
                Astra Redirect
              </Button> */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openPurgeSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenPurgeSuccess(false)}
        sx={{ mb: 5 }}
      >
        <Alert
          onClose={() => setOpenPurgeSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          State Reset!
          <br /> Refresh to see changes.
        </Alert>
      </Snackbar>
    </>
  );
}
