import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import StitchLoading from "@ob/components/StitchLoading";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import { selectPayDestError } from "@ob/layouts/VendorOnboarding/redux/selectors/payDest";

export default function RedirectLanding() {
  const dispatch = useAppDispatch();
  const apiError = useAppSelector(selectPayDestError);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const jwt = params.get("state");
  const { otpVerify } = useParams<{ otpVerify: string }>();

  useEffect(() => {
    dispatch(va.payDest.apiError({ message: "", status: 0 }));
    if (jwt && otpVerify) {
      dispatch(va.auth.setJWT(jwt));
      dispatch(va.auth.setOTPVerify(otpVerify));
      dispatch(va.payDest.getUserDataThenCheckForNewPayDests());
    } else if (!jwt) {
      dispatch(
        va.auth.apiError({
          status: MANUAL_ERROR_CODE,
          message: "No JWT state found in the URL",
        }),
      );
    } else if (!otpVerify) {
      dispatch(
        va.auth.apiError({
          status: MANUAL_ERROR_CODE,
          message: "No auth verification found in the URL",
        }),
      );
    }
  }, []);

  if (apiError.status) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: 5,
        }}
      >
        <Typography>
          An error occurred while trying to process your request. Please try
          again.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pb: 5,
      }}
    >
      <StitchLoading size="large" />
    </Box>
  );
}
