import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import { all, call, put, select, takeLeading } from "redux-saga/effects";
import { fetchVendorConfig } from "@ob/api/vendor/config";
import type { VendorConfigResType } from "@ob/api/types";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";

export default function* configSaga() {
  yield takeLeading(va.config.getConfig, onGetConfig);
}

function* onGetConfig() {
  try {
    const jwt: string = yield select(selectJWT);
    const otpVerify: string = yield select(selectOTPVerify);

    yield all([
      put(va.config.apiFetching(true)),
      put(va.config.apiSuccess(false)),
      put(
        va.config.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);

    const res: VendorConfigResType = yield call(
      fetchVendorConfig,
      jwt,
      otpVerify,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else if (res.error.status === 403) {
        yield all([
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.config.apiError(res.error)),
          put(va.config.apiSuccess(false)),
          put(va.config.apiFetching(false)),
        ]);
      }
    } else {
      yield put(va.config.apiSuccess(true));
      if (res.data?.cardFees) {
        yield put(va.config.setCardFees(res.data.cardFees));
      }
      if (res.data?.bankFees) {
        yield put(va.config.setBankFees(res.data.bankFees));
      }
      yield all([
        put(va.config.apiFetching(false)),
        put(va.config.setKybEnabled(res.data.isVendorKybOnboardingEnabled)),
      ]);
    }
  } catch (error) {
    const errMsg = "An error occured while fetching configuration.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.config.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.config.apiSuccess(false)),
      put(va.config.apiFetching(false)),
    ]);
  }
}
