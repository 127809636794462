import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import type { VendorConfigResType } from "@ob/api/types";

export const fetchVendorConfig = async (
  jwt: string,
  otpVerify: string,
): Promise<VendorConfigResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/config/vendor?auth=${jwt}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`otp_verify:${otpVerify}`)}`,
      },
    },
  );
  return response;
};

export default fetchVendorConfig;
