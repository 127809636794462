import { EXPIRED_TOKEN_ERROR_TYPE } from "./constants";

export default class ExpiredTokenApiError extends Error {
  type: string;

  status: number;

  code: number;

  constructor(m?: string) {
    super(m || "Expired token");
    this.type = EXPIRED_TOKEN_ERROR_TYPE;
    this.status = 403;
    this.code = 0;
  }
}
