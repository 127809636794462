import { all, call, put, select, takeLeading } from "redux-saga/effects";

import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import kybSyncActions from "@ob/layouts/VendorOnboarding/redux/actions/kyb/sync";
import {
  selectBusinessEin,
  selectBusinessName,
  selectBusinessStructure,
  selectDoingBusinessAs,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/business/info";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import {
  selectJWT,
  selectOTPVerify,
} from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { selectBusinessAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/business/address";
import { selectAdminInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/info";
import { selectAdminAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/address";
import { selectControllerInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/info";
import { AdminInfoStateType } from "@ob/layouts/VendorOnboarding/types/kyb/admin/info";
import { AddressStateType } from "@ob/layouts/VendorOnboarding/types/address";
import { BusinessCtrlInfoStateType } from "@ob/layouts/VendorOnboarding/types/kyb/controller/info";
import { selectControllerAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/address";
import {
  KyvUserTypes,
  type AddressReqType,
  type BusinessPersonReqType,
  type UpdateInviteReqType,
  type UpdateInviteResType,
} from "@ob/api/types";
import {
  SingleBeneficialOwnerInfoStateType,
  SingleBeneficialOwnerStateType,
} from "@ob/layouts/VendorOnboarding/types/kyb/owners";
import selectBeneficialOwners from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/owner";
import { formatApiBirthday } from "@ob/utils/formatBirthday";
import tracer from "@ob/tracing";

type BusinessHeaderReqType = {
  business_name: string;
  doing_business_as: string;
  ein: string;
  business_type: BusinessStructureType;
  address: AddressReqType;
};

type BusinessPersonInfoReqType = {
  first_name: string;
  last_name: string;
  email: string;
  ssn: string;
  dob?: string;
  title: string;
};

export default function* businessSyncSaga() {
  yield all([takeLeading(kybSyncActions.submitBusiness, onSubmitKybBusiness)]);
}

export function* onSubmitKybBusiness() {
  try {
    yield all([
      put(va.kyb.sync.apiError({ message: "", status: 0 })),
      put(va.kyb.sync.apiFetching(true)),
      put(va.kyb.sync.apiSuccess(false)),
    ]);

    const jwt: string = yield select(selectJWT);
    const businessReqData: BusinessHeaderReqType = yield call(
      getBusinessHeaderReqData,
    );
    const adminReqData: BusinessPersonReqType = yield call(getAdminReqData);
    const controllerReqData: BusinessPersonReqType =
      yield call(getControllerReqData);
    const ownersReqData: BusinessPersonReqType[] = yield call(getOwnerReqData);
    const updateInviteReqData: UpdateInviteReqType = {
      business: {
        ...businessReqData,
        admin: adminReqData,
        controller: controllerReqData,
        beneficial_owners: ownersReqData,
      },
    };
    const otpVerify: string = yield select(selectOTPVerify);
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      otpVerify,
      updateInviteReqData,
      KyvUserTypes.BUSINESS,
    );

    if ("error" in res) {
      tracer.warn(
        "User unable to submit business information",
        tracer.ids.domain.SAGAS.kyb,
      );
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyb.sync.apiError(res.error)),
          put(va.kyb.sync.apiFetching(false)),
          put(va.kyb.sync.apiSuccess(false)),
        ]);
      } else if (res.error.status === 403) {
        tracer.warn(
          "User unauthorized to submit business information",
          tracer.ids.domain.SAGAS.kyb,
          { jwt },
        );
        yield put(va.routes.redirect("/phone"));
      } else {
        yield all([
          put(va.kyb.sync.apiError(res.error)),
          put(va.kyb.sync.apiFetching(false)),
          put(va.kyb.sync.apiSuccess(false)),
        ]);
      }
    } else {
      yield all([
        put(va.kyb.sync.apiError({ message: "", status: 0 })),
        put(va.kyb.sync.apiFetching(false)),
        put(va.kyb.sync.apiSuccess(true)),
      ]);
      tracer.info(
        "User successfully submitted KYB info",
        tracer.ids.domain.SAGAS.kyb,
      );
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your business information. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyb.sync.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyb.sync.apiFetching(false)),
      put(va.kyb.sync.apiSuccess(false)),
    ]);
  }
}

function* getBusinessHeaderReqData(): Generator<
  any,
  BusinessHeaderReqType,
  any
> {
  const businessName: string = yield select(selectBusinessName);
  const doingBusinessAs: string = yield select(selectDoingBusinessAs);
  const businessEin: string = yield select(selectBusinessEin);
  const businessStructure: BusinessStructureType = yield select(
    selectBusinessStructure,
  );
  const businessAddress: AddressStateType = yield select(selectBusinessAddress);
  const businessAddressReqData = yield call(
    getBusinessAddressReqData,
    businessAddress,
  );

  return {
    business_name: businessName,
    doing_business_as: doingBusinessAs,
    ein: businessEin,
    business_type: businessStructure,
    address: businessAddressReqData,
  };
}

function* getAdminReqData(): Generator<any, BusinessPersonReqType, any> {
  const adminInfo: AdminInfoStateType = yield select(selectAdminInfo);
  const adminAddress: AddressStateType = yield select(selectAdminAddress);

  const personReqData: BusinessPersonInfoReqType =
    getBusinessPersonInfoReqData(adminInfo);
  const addressReqData: AddressReqType =
    getBusinessAddressReqData(adminAddress);

  return {
    ...personReqData,
    address: addressReqData,
  };
}

function* getControllerReqData(): Generator<any, BusinessPersonReqType, any> {
  const ctrlInfo: BusinessCtrlInfoStateType =
    yield select(selectControllerInfo);
  const ctrlAddress: AddressStateType = yield select(selectControllerAddress);
  const personReqData: BusinessPersonInfoReqType = yield call(
    getBusinessPersonInfoReqData,
    ctrlInfo,
  );
  const addressReqData: AddressReqType = yield call(
    getBusinessAddressReqData,
    ctrlAddress,
  );

  return {
    ...personReqData,
    address: addressReqData,
  };
}

function* getOwnerReqData(): Generator<any, BusinessPersonReqType[], any> {
  const owners: SingleBeneficialOwnerStateType[] = yield select(
    selectBeneficialOwners,
  );

  const ownersReqData: BusinessPersonReqType[] = [];
  for (const owner of owners) {
    const ownerInfoReqData = yield call(
      getBusinessPersonInfoReqData,
      owner.info,
    );
    const ownerAddressReqData = yield call(
      getBusinessAddressReqData,
      owner.address,
    );
    const ownerReqData: BusinessPersonReqType = {
      ...ownerInfoReqData,
      address: ownerAddressReqData,
    };
    ownersReqData.push(ownerReqData);
  }
  return ownersReqData;
}

function getBusinessPersonInfoReqData(
  _info:
    | AdminInfoStateType
    | BusinessCtrlInfoStateType
    | SingleBeneficialOwnerInfoStateType,
): BusinessPersonInfoReqType {
  const info = { ..._info };
  if (info.dob !== "") {
    info.dob = formatApiBirthday(info.dob);
  }
  const infoReqData: BusinessPersonInfoReqType = {
    first_name: info.firstName,
    last_name: info.lastName,
    email: info.email,
    ssn: info.ssn,
    dob: info.dob,
    title: info.title,
  };
  if (!infoReqData.dob) {
    delete infoReqData.dob;
  }
  return infoReqData;
}

function getBusinessAddressReqData(_address: AddressStateType): AddressReqType {
  const address = { ..._address };
  return {
    line1: address.street1,
    line2: address.street2,
    city: address.city,
    state: address.state,
    postal_code: address.zip,
  };
}
