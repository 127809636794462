import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";

export type DirtyAddressDbType = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
};
export type AddressDbType = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
};

export type AccountType = "savings" | "checking";

export type UserStatusType =
  | "unregistered"
  | "manual_review"
  | "approved"
  | "suspended"
  | "rejected"
  | "document"
  | "pending";

export function isKeyInDBAddressType(key: string): key is keyof AddressDbType {
  const addressKeys = ["street1", "street2", "city", "state", "zip"];
  return addressKeys.includes(key);
}

export function isKeyBusinessStructureType(
  key: string,
): key is BusinessStructureType {
  const businessStructureTypes: string[] = Object.values(BusinessStructureType);
  return businessStructureTypes.includes(key);
}

export type CreditCardDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  cardCompany: string;
  expiration: string;
  disabled: boolean;
  isDefault: boolean;
  isPushEnabled: boolean;
  mask: string;
};

export type BankAcctDbType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  disabled: boolean;
  institutionName: string;
  subType: AccountType;
  name: string;
  isDefault: boolean;
  mask: string;
};

export type BusinessPersonDbType = {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  ssnExists: boolean;
  title?: string;
  address: AddressDbType;
};

export type BusinessInviteDbType = {
  businessName: string;
  doingBusinessAs: string;
  ein: string;
  businessType: BusinessStructureType;
  address: AddressDbType;
  admin: BusinessPersonDbType;
  controller: BusinessPersonDbType;
  beneficialOwners: BusinessPersonDbType[];
};

export type UserDBType = {
  id: string;
  vendorId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  taxIdExists: boolean;
  dob: string;
  address: AddressDbType;
  needsPaymentDest: boolean;
  status: UserStatusType;
  userType: string;
  business: BusinessInviteDbType;
};
