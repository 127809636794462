/* eslint-disable no-shadow */

import {
  UserDBType,
  UserStatusType,
  CreditCardDbType,
  BankAcctDbType,
  AccountType,
} from "@ob/redux/types/dbTypes";
import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import { ErrorResType } from "@ob/api/utils/safeFetch";
import { FeesPayloadType } from "@ob/layouts/VendorOnboarding/types/config";

export type AddressReqType = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export type LoadInviteResType = { data: UserDBType } | ErrorResType;

export type BusinessPersonReqType = {
  first_name: string;
  last_name: string;
  email: string;
  ssn: string;
  dob?: string;
  address: AddressReqType;
  title: string;
};
export type BusinessInfoReqType = {
  business_name: string;
  doing_business_as: string;
  ein: string;
  business_type: BusinessStructureType;
  address: AddressReqType;
  admin: BusinessPersonReqType;
  controller: BusinessPersonReqType;
  beneficial_owners: BusinessPersonReqType[];
};

export type UpdateInviteReqType =
  | { address: AddressReqType }
  | { first_name: string; last_name: string }
  | { email: string }
  | { phone: string }
  | { tax_id: string }
  | { dob: string }
  | { business: BusinessInfoReqType };

export type UpdateInviteResType = { data: UserDBType } | ErrorResType;

export type SubmitInviteReqType = {
  phone: string;
  address: AddressReqType;
  email: string;
  first_name: string;
  last_name: string;
  tax_id: string;
  dob: string;
};

export type SubmitInviteResType =
  | {
      data: {
        userIntentId: string;
        sessionToken: string;
        clientId: string;
      };
    }
  | ErrorResType;

export type GetInviteStatusResType =
  | { data: { status: UserStatusType } }
  | ErrorResType;

export type SubmitPhoneResType = object | ErrorResType;
export type SubmitPhoneCodeResType =
  | { data: { otpVerify: string } }
  | ErrorResType;
export type GetOTPStatusResType =
  | { data: { verified: boolean } }
  | ErrorResType;
export type VendorReloginLinkResType = { data: null } | ErrorResType;

export type BankInfoReqType = {
  accountType: AccountType;
  accountNumber: string;
  routingNumber: string;
};
export type GetCardsResType =
  | { data: { cards: CreditCardDbType[] } }
  | ErrorResType;
export type GetAcctsResType =
  | { data: { accounts: BankAcctDbType[] } }
  | ErrorResType;
export type DeleteCardResType = ErrorResType | { data: null };
export type DeleteBankResType = ErrorResType | { data: null };
export type CreateAcctResType = ErrorResType | { data: null };

export type VendorConfigResType =
  | {
      data: {
        isVendorKybOnboardingEnabled: boolean;
        cardFees: FeesPayloadType | null;
        bankFees: FeesPayloadType | null;
      };
    }
  | ErrorResType;

export type AstraParamsResType =
  | {
      data: {
        clientId: string;
        sessionToken: string;
      };
    }
  | ErrorResType;

export enum KyvUserTypes {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
  UNKNOWN = "unknown",
}

export function isKyvUserTypes(arg: string | undefined): arg is KyvUserTypes {
  if (!arg) return false;
  return Object.values(KyvUserTypes).includes(arg as KyvUserTypes);
}
